<template>
  <div class="sidebar-menu fixed mw-100 bg-cl-secondary">
    <div class="row brdr-bottom-1 brdr-cl-grey-smoke">
      <div
        v-if="submenu.depth"
        class="col-xs bg-cl-primary"
      >
        <sub-btn type="back" class="bg-cl-transparent brdr-none" />
      </div>
      <div
        v-if="!submenu.depth"
        @click="closeMenu"
        class="bg-cl-primary"
      >
        <router-link
          class="block pl33 py20 no-underline uppercase weight-600"
          :to="localizedRoute('/')"
          exact
        >
          {{ $t('Strona główna') }}
        </router-link>
      </div>
      <div class="col-xs bg-cl-primary">
        <button
          type="button"
          :aria-label="$t('Close')"
          class="w-100 inline-flex end-xs bg-cl-transparent brdr-none p0 close-btn"
          @click="closeMenu"
        >
          <img :src="'/assets/svg/Krzyzyk.svg'" width="18px" height="18px" class="close-black p17" alt="krzyzyk">
        </button>
      </div>
    </div>
    <div class="sidebar-menu__container row" ref="container">
      <div class="col-xs-12">
        <ul class="p0 m0 relative sidebar-menu__list" :style="mainListStyles">
          <li
            class="brdr-bottom-1 brdr-cl-grey-smoke bg-cl-primary flex"
            :key="category.slug"
            @click="closeMenu"
            v-for="category in visibleCategories"
          >
            <div
              v-if="isCurrentMenuShowed"
              class="subcategory-item"
            >
              <sub-btn
                v-if="category.children_count > 0"
                class="bg-cl-transparent brdr-none h5 middle-xs"
                :id="category.id"
                :name="category.name"
              />
              <router-link
                v-else
                class="px25 py20 no-underline col-xs"
                :to="localizedRoute('/' + category.url_path)"
              >
                {{ category.name }}
              </router-link>
            </div>

            <sub-category
              :category-links="category.children_data"
              :id="category.id"
              :parent-slug="category.slug"
              :parent-path="category.url_path"
            />
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-grey-smoke no-underline uppercase"
              :to="localizedRoute('/i/wirtualny-spacer')"
              exact
            >
              {{ $t('Wirtualny spacer') }}
            </router-link>
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-grey-smoke no-underline uppercase"
              :to="localizedRoute('/kategorie-produktow?bestseller=1')"
              exact
            >
              {{ $t('Bestsellery') }}
            </router-link>
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-grey-smoke no-underline uppercase"
              :to="localizedRoute('/kategorie-produktow?sale=true')"
              exact
            >
              {{ $t('Wyprzedaż') }}
            </router-link>
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-grey-smoke no-underline"
              :to="localizedRoute('/porady')"
              exact
            >
              {{ $t('Porady') }}
            </router-link>
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-grey-smoke no-underline"
              :to="localizedRoute('/i/kontakt')"
              exact
            >
              {{ $t('Kontakt') }}
            </router-link>
          </li>
          <li
            v-if="compareIsActive && isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-grey-smoke no-underline"
              :to="localizedRoute('/compare')"
              exact
            >
              {{ $t('Compare products') }}
            </router-link>
          </li>
          <!-- <li
            @click="login"
            class="brdr-bottom-1 brdr-cl-grey-smoke bg-cl-primary flex"
          >
            <sub-btn
              v-if="currentUser"
              :name="$t('My account')"
              class="bg-cl-transparent brdr-none fs-medium-small"
            />
            <sub-category
              v-if="currentUser"
              :my-account-links="myAccountLinks"
              :id="'foo'"
              @click.native="closeMenu"
            />
            <a
              v-if="!currentUser && isCurrentMenuShowed"
              href="#"
              @click.prevent="closeMenu"
              class="block w-100 px25 py20 no-underline fs-medium-small"
            >
              {{ $t('My account') }}
            </a>
          </li> -->
          <a v-if="isCurrentMenuShowed" href="tel:22-397-43-92" class="col-xs-12 center-flex-text bg-cl-primary-orange cl-white sticky-position">
            <p class="m0 weight-100 py20 pr20">
              ZAMÓW TELEFONICZNIE
            </p>
            <i class="icon-phone-order cl-white icon-size" />
          </a>
          <li v-if="isCurrentMenuShowed" class="col-xs-12 flex middle-xs pt25 pb40 social-container">
            <a
              class="social-icon mx10 brdr-circle no-underline"
              href="https://pl-pl.facebook.com/AG.Grzejniki.Design/"
              :aria-label="$t('Go to Facebook')"
              target="_blank"
              rel="noreferrer"
            >
              <i class="icon-fb icon-size cl-primary-orange" />
            </a>
            <a
              class="social-icon mx10 brdr-circle no-underline"
              href="https://www.instagram.com/ag_grzejniki_design/"
              :aria-label="$t('Go to Instagram')"
              target="_blank"
              rel="noreferrer"
            >
              <i class="icon-Insta icon-size cl-primary-orange" />
            </a>
            <a
              class="social-icon mx10 brdr-circle no-underline"
              href="https://www.youtube.com/channel/UCz64TTccXJ61aO_rnFNvw7g"
              :aria-label="$t('Go to Youtube')"
              target="_blank"
              rel="noreferrer"
            >
              <i class="icon-youtube icon-size cl-primary-orange" />
            </a>
            <a
              class="social-icon mx10 brdr-circle no-underline"
              href="https://twitter.com/AgGrzejniki?lang=en"
              :aria-label="$t('Go to Twitter')"
              target="_blank"
              rel="noreferrer"
            >
              <i class="icon-twitter icon-size cl-primary-orange" />
            </a>
            <a
              class="social-icon mx10 brdr-circle no-underline"
              href="https://pl.pinterest.com/aggrzejnikidesign/"
              :aria-label="$t('Go to Pinterest')"
              target="_blank"
              rel="noreferrer"
            >
              <i class="icon-pinterest icon-size cl-primary-orange" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import i18n from '@vue-storefront/i18n'
import SidebarMenu from '@vue-storefront/core/compatibility/components/blocks/SidebarMenu/SidebarMenu'
import SubBtn from 'theme/components/core/blocks/SidebarMenu/SubBtn'
import SubCategory from 'theme/components/core/blocks/SidebarMenu/SubCategory'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default {
  components: {
    SubCategory,
    SubBtn
  },
  mixins: [SidebarMenu],
  data () {
    return {
      myAccountLinks: [
        {
          id: 1,
          name: i18n.t('My profile'),
          url: '/my-account'
        },
        {
          id: 2,
          name: i18n.t('My shipping details'),
          url: '/my-account/shipping-details'
        },
        {
          id: 3,
          name: i18n.t('My newsletter'),
          url: '/my-account/newsletter'
        },
        {
          id: 4,
          name: i18n.t('My orders'),
          url: '/my-account/orders'
        }
      ],
      componentLoaded: false
    }
  },
  computed: {
    mainListStyles () {
      return this.submenu.depth ? `transform: translateX(${this.submenu.depth * 100}%)` : false
    },
    ...mapState({
      submenu: state => state.ui.submenu,
      currentUser: state => state.user.current
    }),
    getSubmenu () {
      return this.submenu
    },
    visibleCategories () {
      return this.categories.filter(category => {
        return category.product_count > 0 || category.children_count > 0
      })
    },
    isCurrentMenuShowed () {
      return !this.getSubmenu || !this.getSubmenu.depth
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true;
      disableBodyScroll(this.$refs.container)
    })
  },
  destroyed () {
    clearAllBodyScrollLocks()
  },
  methods: {
    login () {
      if (!this.currentUser && this.isCurrentMenuShowed) {
        this.$nextTick(() => {
          this.$store.commit('ui/setAuthElem', 'login')
          this.$bus.$emit('modal-show', 'modal-signup')
          this.$router.push({ name: 'my-account' })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$bg-secondary: color(secondary, $colors-background);
$color-gainsboro: color(gainsboro);
$color-matterhorn: color(matterhorn);
$color-mine-shaft: color(mine-shaft);

.icon-size {
  font-size: 35px;
}
.sticky-position {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
}
.social-container {
  justify-content: space-evenly;
  @media only screen and (min-device-width : 375px) and (max-device-width : 812px)  and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
    padding-bottom: 150px;
  }
}
.close-black {
  filter: invert(100%);
}
.sidebar-menu {
  height: 100vh;
  width: 350px;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 100vh;
  }

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 115px);
    -webkit-overflow-scrolling: touch;
  }

  &__list {
    transition: transform $duration-main $motion-main;
  }

  ul {
    list-style-type: none;
  }

  li {
    &:hover,
    &:focus {
      background-color: $color-gainsboro;
    }
    &.bg-cl-primary {
      &:hover,
      &:focus {
        background-color: $bg-secondary;
      }
    }
  }

  .subcategory-item {
    display: flex;
    width: 100%;
  }

  .close-btn {
    i {
      color: $color-gainsboro;
    }
    &:hover,
    &:focus {
      i {
        color: $color-matterhorn;
      }
    }
  }

}
</style>
