<template>
  <div class="fixed notifications save-area">
    <transition-group name="fade-in-down">
      <div
        class="notification mt30 border-box cl-white"
        v-for="(notification, index) in notifications"
        :key="`${notification.type}_${index}`"
        :class="[[notification.item && notification.type === 'success' ? 'success_product' : notification.type],
                 [ notification.item && notification.type === 'success' ? 'custom-width' : 'original-width']]"
      >
        <div
          data-testid="notificationMessage"
        >
          <template v-if="notification.item && notification.type === 'success'">
            <div class="message">
              <div class="flex p10 between-xs middle-xs custom-border" v-show="isMobile && !isAccess && isRelatedProducts">
                <p class="m0 fs-medium-small weight-500">
                  Sprawdź, czy nie potrzebujesz dokupić jeszcze:
                </p>
              </div>
              <related-product-tab type="crosssell" is-notification="true" v-if="isMobile" />
              <div class="mobile-green-box bg-cl-forest-green flex middle-xs center-xs" v-if="isMobile">
                <p class="m0 cl-white weight-300">
                  DODANO DO KOSZYKA
                </p>
              </div>
              <div class="flex product-item">
                <div class="image-box flex middle-xs center-xs" v-if="notification.item.image">
                  <img
                    class="product-image"
                    alt="product-image"
                    :src="thumbnailPath"
                  >
                </div>
                <div class="product-desc container">
                  <div class="row" v-if="!isMobile">
                    <p class="col-xs-12 weight-500 cl-primary-orange h4">
                      {{ $t('PRODUKT ZOSTAŁ DODANY DO KOSZYKA!') }}
                    </p>
                  </div>
                  <div class="row relative">
                    <div class="col-xs-12 col-md-8">
                      <p class="m0 product-name weight-500">
                        {{ notification.item.name }}
                      </p>
                      <div v-show="!isMobile">
                        <span class="cl-matterhorn">{{ notification.item.dostawca }}</span><br>
                        <span class="cl-matterhorn" v-for="(i, index1) in notification.item.options" :key="index1" v-if="i.label === 'Size'">
                          {{ i.value }}
                        </span>
                      </div>
                      <div v-show="isMobile" class="fs-medium-small">
                        <p class="m0">
                          Kolor/wzór: {{ notification.item.dostawca }}
                        </p>
                        <p class="my5" v-for="(i, index1) in notification.item.options" :key="index1" v-if="i.label === 'Size'">
                          Wymiary: {{ i.value }}
                        </p>
                        <p class="m0" v-if="notification.item.qty">
                          Ilość: {{ notification.item.qty }}
                        </p>
                      </div>
                    </div>
                    <!--
                      <p
                        class="price cl-primary-orange weight-700 visible-xs"
                        v-if="notification.item.special_price && parseFloat(notification.item.special_price) > 0"
                      >
                        {{ notification.item.price_incl_tax | price }}
                      </p>
                      <p
                        class="price cl-primary-orange weight-700 visible-xs"
                        v-if="!notification.item.special_price && parseFloat(notification.item.price_incl_tax) > 0"
                      >
                        {{ notification.item.price_incl_tax | price }}
                      </p>
                      -->
                    <span class="visible-xs-and-tablet mobile-price">{{ notification.item.final_price | price }}</span>
                    <div class="hidden-xs-and-tablet col-xs-4">
                      <p class="m0" v-if="notification.item.priceInclTax">
                        Cena: <span class="weight-600 fs-medium">{{ price(notification.item) | price }}</span>
                      </p>
                      <p class="m0" v-if="notification.item.qty">
                        Ilość: {{ notification.item.qty }}
                      </p>
                    </div>
                  </div>
                  <div class="row pt15 around-sm" v-if="!isTablet">
                    <div
                      data-testid="notificationAction2custom"
                      class="col-xs-5 uppercase h5 custom-action-2 pointer weight-300 center-flex-text"
                      @click="execAction(notification.action2, index)"
                    >
                      {{ $t('Continue to payment') }}
                    </div>
                    <div
                      data-testid="notificationAction1custom"
                      class="col-xs-5 uppercase h5 custom-action-1 pointer weight-400"
                      @click="execAction(notification.action1, index)"
                      v-if="notification.action1"
                    >
                      {{ $t('Return to shopping') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="p15">
              {{ notification.message }}
            </div>
          </template>
        </div>
        <div :class="[ notification.item && notification.type === 'success' ? 'custom-bottom' : 'actions']">
          <template v-if="notification.item && notification.type === 'success'">
            <div class="tooltip bg-cl-dark-blue flex middle-xs around-xs" v-if="showTooltip && !isShippedForFree">
              <i class="icon-delivery cl-white fs-medium" />
              <p class="tooltip-text cl-white m0">
                Czy wiesz, że składając zamówienie powyżej 5000zł otrzymasz dostawę gratis?<br>
                Kup jeszcze za {{ shippedCount | price }} zł a odblokujesz darmową dostawę
              </p>
              <img :src="'/assets/svg/Krzyzyk.svg'" class="pointer krzyzyk-icon" alt="krzyzyk" @click="showTooltip = false">
            </div>
            <div class="px15" v-if="!isTablet">
              <div>
                <p v-show="product.related['crosssell'] && product.related['crosssell'].length > 0" class="weight-500 mb0">
                  Sprawdź, czy nie potrzebujesz dokupić jeszcze:
                </p>
                <related-product-tab type="crosssell" />
              </div>
            </div>
            <div class="row mobile-actions" v-if="isTablet">
              <div
                class="col-xs-3 flex middle-xs center-xs border-right-mobile"
                data-testid="notificationAction1custom"
                @click="execAction(notification.action1, index)"
                v-if="notification.action1"
              >
                <span>OK</span>
              </div>
              <div
                class="col-xs-9 flex middle-xs center-xs"
                data-testid="notificationAction2custom"
                @click="execAction(notification.action2, index)"
              >
                <span>PRZEJDŹ DO KASY</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="py10 px20 pointer weight-400 action-border notification-action uppercase"
              :class="`border-${notification.type}`"
              id="notificationAction1"
              data-testid="notificationAction1"
              @click="execAction(notification.action1, index)"
            >
              {{ notification.action1.label }}
            </div>
            <div
              class="py10 px20 pointer weight-400 notification-action uppercase"
              id="notificationAction2"
              data-testid="notificationAction2"
              @click="execAction(notification.action2, index)"
              v-if="notification.action2"
            >
              {{ notification.action2.label }}
            </div>
          </template>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import config from 'config'
import { MobileDetected } from './mobileDetected.ts'
import { Notification } from '@vue-storefront/core/modules/notification/components/Notification'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import RelatedProductTab from './blocks/ProductTabs/RelatedProductTab.vue'
import { getCustomOptionValues, getCustomOptionPriceDelta } from '@vue-storefront/core/modules/catalog/helpers/customOption'
import { getBundleOptionsValues, getBundleOptionPrice } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import get from 'lodash-es/get'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapGetters } from 'vuex'
import { isInAccessorizes } from '@vue-storefront/core/helpers/index'

export default {
  mixins: [Notification, MobileDetected],
  components: { RelatedProductTab },
  data () {
    return {
      showTooltip: true
    }
  },
  computed: {
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      getCurrentCustomOptions: 'product/getCurrentCustomOptions',
      totals: 'cart/getTotals'
    }),
    isRelatedProducts () {
      return !!this.getCurrentProduct.product_links
    },
    isAccess () {
      return isInAccessorizes(this.getBreadcrumbsRoutes)
    },
    product () {
      return this.$store.state.product
    },
    storeView () {
      return currentStoreView()
    },
    thumbnailPath () {
      return this.getThumbnail()
    },
    isShippedForFree () {
      if (this.shippedCount <= 0) {
        return true
      } else {
        return false
      }
    },
    shippedCount () {
      let segment = this.totals.find(x => x.code === 'subtotal')
      if (segment && (segment.code === 'subtotal' || segment.code === 'subtotalInclTax')) {
        return config.server.freeDeliveryValue - segment.value
      } else {
        return config.server.freeDeliveryValue
      }
    },
    isSuccessProduct () {
      let isSuccess = true
      for (var i = 0; i < this.notifications.length; i++) {
        if (!this.notifications[i].item && this.notifications[i].type === 'success') {
          isSuccess = false
        }
      }
      return isSuccess
    }
  },
  methods: {
    price (item) {
      const customOptionPrice = this.customOptionsPriceDelta(item).priceInclTax
      const price = (item.priceInclTax + customOptionPrice) * item.qty
      return price
    },
    customOptionsPriceDelta (item) {
      const priceDelta = getCustomOptionPriceDelta(
        getCustomOptionValues(Object.values(this.getCurrentCustomOptions), item.custom_options),
        item
      )
      return priceDelta
    },
    getThumbnail () {
      let notification
      for (var i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].item && this.notifications[i].type === 'success') {
          notification = this.notifications[i].item.image
        }
      }
      return _thumbnailHelper(notification, 250, 250)
    },
    execAction (action, index) {
      if (action.action) {
        // for backward compatibility
        if (action.action === 'close') {
          this.$store.dispatch('notification/removeNotification', index)
        } else if (action.action === 'toCompare') {
          this.$router.push('/compare')
        } else {
          action.action()
        }
      }
      this.$store.dispatch('notification/removeNotification', index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$z-index-notification: map-get($z-index, notification);
$color-error: color(error);
$color-success: color(success);
$color-warning: color(warning);
$color-info: color(accent);
$color-white: color(white);
$color-action: color(black);
$primary-orange: color(primary-orange);

.mobile-green-box {
  height: 40px;
}
.custom-border {
 border-bottom: 1px solid #E5E5E5;
}
.message {
  padding: 15px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.custom-bottom {
  .tooltip {
    height: 60px;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    @media (max-width: 767px) {
      display: none;
      // padding: 0 8px;
    }
    .tooltip-text {
      font-size: 14px;
      @media (max-width: 767px) {
        font-size: 12px;
        padding-left: 8px;
      }
    }
    .krzyzyk-icon {
      width: 24px;
      height: 24px;
      @media (max-width: 767px) {
        width: 18px;
        height: 18px;
      }
    }
  }
  .mobile-actions {
    height: 40px;
    border-top: 2px solid #E5E5E5;
    .border-right-mobile {
      border-right: 2px solid #E5E5E5;
    }
  }
}
.success_product {
  background: $color-white;
  color: #343434;
  .product-item {
    @media (max-width: 767px) {
      padding: 10px 0;
    }
    .image-box {
      width: 30%;
    }
    .product-desc {
      width: 70%;
      .product-name {
        font-size: 16px;
        @media (max-width: 767px) {
          font-size: 14px;
          padding-bottom: 10px;
        }
      }
      .mobile-price {
        position: absolute;
        bottom: 15px;
        right: 20px;
      }
      .price-box {
        width: 30%;
        color: #343434;
        height: fit-content;
      }
    }
  }
}
.close-black {
  filter: invert(100%);
}
.custom-action-2 {
  color: white;
  background-color: $primary-orange;
  padding: 8px 0;
  text-align: center;
  border-radius: 50px;
}
.custom-action-1 {
  border: 1px solid #000000;
  padding: 8px 0;
  text-align: center;
  border-radius: 50px;
}
.original-width {
  width: 320px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    width: auto;
  }
}
.custom-width {
  width:700px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    width: auto;
  }
}
.product-image {
  height: auto;
  max-height: 350px;
  width: auto;
  max-width: 100%;
  @media (max-width: 767px) {
    max-height: 150px;
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    max-height: 200px;
  }
}
.notifications {
  top: 100px;
  right: 5%;
  z-index: 2147483649;

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;

    .fade-in-down-enter,
    .fade-in-down-leave-to {
      transform: translateY(100%);
    }
  }
}
.notification {
  box-shadow: 0px 0px 35px -5px rgba($color-action, .7);

  &:first-child  {
    margin-top: 0;
  }
}

.actions {
  display: flex;
  justify-content: space-between;

  .notification-action {
    background: rgba($color-action, .2);
  }

  #notificationAction2 {
    width: 100%;
  }
}
.success {
  background: $color-success;
}
.error {
  background: $color-error;
}
.warning {
  background: $color-warning;
}
.info {
  background: $color-info;
}
.action-border {
  border-right: 2px solid transparent;
  &.border-success {
    border-right-color: $color-success;
  }
  &.border-error {
    border-color: $color-error;
  }
  &.border-warning {
    border-color: $color-warning;
  }
  &.border-info {
    border-color: $color-info;
  }
}
</style>
